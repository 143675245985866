import React from "react";
import { Select } from "antd";

const CustomSelect = ({
  label,
  name,
  value,
  options,
  disabled,
  onChange,
  disabledOption,
  condition,
  onBlur,
  showIcon,
  form,
  placeholder,
  errors,
  touched,
  style,
  code,
  codeColor,
  showCodeColor,
}) => {

  const commonSelectStyles = {
    width: "100%",
    borderColor: "#000",
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "30px",
    display: "flex",
  };

  const error = errors[name];
  const touch = touched[name];

  if (!condition) return;
  return (
    <>
      {condition !== null && (
        <div className="wrapper-select" style={style}>
          {label && (
            <label htmlFor={name} className="input-text">
              {label}
            </label>
          )}
          <Select
            placeholder={placeholder}
            name={name}
            value={value || null}
            disabled={disabled}
            size="large"
            bordered={false}
            className={
              value
                ? "form-element-input small"
                : "form-element-input calendar not-filled"
            }
            style={commonSelectStyles}
            onChange={(val) => onChange(name, val)}
            onBlur={onBlur}
          >
            {options.map((option) => (
              <Select.Option
                key={option.key}
                value={option.value}
                disabled={option.value === disabledOption}
                className="option"
              >
                <div
                  className="img-container element-status"
                  style={
                    showIcon ? { background: option.backgroundColor } : null
                  }
                >
                  {/* {showIcon && <img src={option.icon} alt="icon" />} */}
                  <div>{option.label}</div>
                  {code && showCodeColor && (
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        background: codeColor,
                        display: "flex",
                        padding: "2px",
                        alignItems: "center",
                        borderRadius: "5px",
                        height: "20px",
                      }}
                    >
                      {code}
                    </div>
                  )}
                </div>
              </Select.Option>
            ))}
          </Select>
          {error && touch ? (
            <div className="error-displayer-form-modal">{error}</div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default CustomSelect;

import { useSelector } from "react-redux";
import {
  convertToYYYYMM,
  convertToYYYYMMDD,
  parseDate,
} from "../utilities/filters";

export const useFiltersAndDates = () => {
  const { selectedMonths, mode, selectedMonthsSynthesis } = useSelector(
    (state) => state.layout
  );

  const {
    noFilterChecked,
    accountTreso,
    footerAccountTreso,
    noFilterCheckedCompta,
    footerComptaFilter,
    filterValuesCompta,
  } = useSelector((state) => state.categories);

  const transformFilterValues = (filters) => {
    const realFilters = filters.filter((item) => item.type === "réel");
    const simulationFilters = filters.filter(
      (item) => item.type === "simulation"
    );

    const combinedRealFilters =
      realFilters.length > 0
        ? {
            type: "réel",
            status: realFilters
              .map((item) => item.status)
              .filter(Boolean)
              .join(","),
          }
        : null;

    const result = [];
    if (combinedRealFilters) result.push(combinedRealFilters);
    result.push(...simulationFilters);

    return result;
  };

  const transformedValues = transformFilterValues(filterValuesCompta);

  const constructFilters = () => {
    const tableConstructedFilters = {
      filterOptions: mode === "compta" ? transformedValues : accountTreso,
      noFilterChecked:
        mode === "compta" ? noFilterCheckedCompta : noFilterChecked,
    };
    const footerConstructedFilters =
      mode === "compta" ? footerComptaFilter : footerAccountTreso;
    return { footerConstructedFilters, tableConstructedFilters };
  };

  const getStartAndEndDate = () => {
    const startDate = convertToYYYYMM(selectedMonths[0]);
    const endDate = convertToYYYYMM(selectedMonths[selectedMonths.length - 1]);

    return { startDate, endDate };
  };

  const getStartAndEndDateSynthesis = () => {
    const startDateSynthesis = convertToYYYYMM(selectedMonthsSynthesis[0]);
    const endDateSynthesis = convertToYYYYMM(
      selectedMonthsSynthesis[selectedMonthsSynthesis.length - 1]
    );

    return { startDateSynthesis, endDateSynthesis };
  };
  const getStartAndEndDateGraph = () => {
    const startDate = convertToYYYYMMDD(selectedMonths[0]);
    const endDate = convertToYYYYMMDD(
      selectedMonths[selectedMonths.length - 1],
      "lastDay"
    );

    return { startDate, endDate };
  };

  const getStartAndEndDateSideBar = () => {
    const start = selectedMonths?.map((month) => parseDate(month))[0];
    const end = selectedMonths?.map((month) => parseDate(month))[
      selectedMonths?.length - 1
    ];

    return { start, end };
  };
  return {
    constructFilters,
    getStartAndEndDate,
    getStartAndEndDateSynthesis,
    getStartAndEndDateGraph,
    getStartAndEndDateSideBar,
    noFilterChecked,
  };
};

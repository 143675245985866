import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { message, Dropdown, Menu, Tooltip } from "antd";
import { Field, useFormikContext } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import Delete from "../../assets/img/delete.png";
import edit from "../../assets/img/edit.png";
import { closeModal, openModal } from "../../data/slices/modals";
import CustomInput from "../Input";
import { useSelector } from "../../data/store";
import "./index.scss";
import { checkBeforeDelete, deleteAccount } from "../../data/slices/accounts";

const CustomDropdown = ({ projectId, values, onBlur }) => {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext(); // Get setFieldValue from Formik context
  const { accounts } = useSelector((state) => state.accounts);

  const handleDelete = (name, id, event) => {
    event.stopPropagation();
    dispatch(checkBeforeDelete({ projectId, accountId: id })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (res.payload?.data?.data) {
          message.error(
            "Impossible de supprimer ce compte car des écritures lui sont affectées !"
          );
        } else {
          dispatch(
            openModal("delete-compte-modal", {
              accountId: id,
              name,
              abandon: () => dispatch(closeModal("delete-compte-modal")),
              onClose: () => {
                dispatch(deleteAccount({ projectId, accountId: id })).then(
                  (response) => {
                    if (response.meta.requestStatus === "fulfilled") {
                      message.success("Compte supprimé avec succès");
                    }
                  }
                );
                dispatch(closeModal("delete-compte-modal"));
              },
            })
          );
        }
      }
    });
  };

  const openCreateAccount = (data) =>
    dispatch(openModal("create-compte-modal", data));

  const handleMenuSelect = (option) => {
    setFieldValue("accountTresoId", option.id);
    setFieldValue("accountTresoLabel", option.name);
    setFieldValue("accountCode", option.code);
    setFieldValue("accountColor", option.color);
  };

  const menu = (
    <Menu>
      {accounts?.map((option) => (
        <Menu.Item
          key={option?.id}
          className={"menu-option"}
          onClick={() => handleMenuSelect(option)}
        >
          <span>
            <span>{option?.name}</span>
            <span
              className="compte-displayer-filter"
              style={{ backgroundColor: option?.color }}
            >
              {option?.code}
            </span>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "18px",
            }}
          >
            <Tooltip title="Editer le compte">
              <div
                onClick={() =>
                  openCreateAccount({
                    values: option,
                    isEdit: true,
                    projectId,
                  })
                }
                className="icon-menu-option"
              >
                <img src={edit} alt="edit" />
              </div>
            </Tooltip>
            <Tooltip title="Supprimer le compte">
              <div
                onClick={(e) => {
                  if (option?.type === "PRINCIPAL") return;
                  handleDelete(option.name, option.id, e);
                }}
                className={`icon-menu-option ${
                  option?.type === "PRINCIPAL" ? "disabled" : ""
                }`}
              >
                <img src={Delete} alt="delete" />
              </div>
            </Tooltip>
          </div>
        </Menu.Item>
      ))}
      <Menu.Item
        onClick={() =>
          openCreateAccount({
            projectId,
          })
        }
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <PlusOutlined />
        <span>Ajouter un compte</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <div style={{ position: "relative", width: "49%" }}>
          <Field
            component={CustomInput}
            readOnly
            label="Compte de trésorerie:"
            name="accountTresoLabel"
            type="text"
            style={{ width: "100%", cursor: "pointer" }}
            className={"form-element-input small input-wrapper-writing"}
            placeholder="Saisir un code"
            value={`${values?.accountTresoLabel || ""} - SomeWord`}
            onBlur={onBlur}
          />
          <DownOutlined
            style={{
              position: "absolute",
              right: "10px",
              top: "75%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
              width: "16px",
            }}
          />
        </div>
      </Dropdown>
    </>
  );
};

export default CustomDropdown;

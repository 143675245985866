import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utilities/axios";

const initialState = {
  isCreateProject: false,
  accounts: [],
  project: null,
  deleteStatus: "idle",
  updateStatus: "idle",
  projectStatus: "idle",
  status: "idle",
  createStatus: "idle",
  checking: false,
  canDelete: false,
};

export const checkBeforeDelete = createAsyncThunk(
  "accounts/checkBeforeDelete",
  async (values) => {
    let data;
    try {
      const response = await axios.get(
        `api/projects/${values.projectId}/account-treso/${values.accountId}/have-elements`
      );
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAccounts = createAsyncThunk(
  "accounts/getAccounts",
  async (projectId) => {
    let data;
    try {
      const response = await axios.get(
        `api/projects/${projectId}/account-treso`
      );
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "accounts/deleteAccount",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(
        `api/projects/${body?.projectId}/account-treso/${body?.accountId}`
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getAccounts(body?.projectId));
        return data.message;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

// export const getOneProject = createAsyncThunk(
//   "accounts/getOneProject",
//   async (id) => {
//     let data;
//     try {
//       const response = await axios.get(`api/projects/${id}`);
//       data = await response.data;
//       if (response.status === 200) {
//         return {
//           data: data,
//           message: data.message,
//         };
//       }
//       throw new Error(response.statusText);
//     } catch (err) {
//       return Promise.reject(err.message ? err.message : err);
//     }
//   }
// );

export const updateAccount = createAsyncThunk(
  "accounts/updateAccount",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `api/projects/${body?.projectId}/account-treso/${body?.accountId}`,
        body.values
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createAccount = createAsyncThunk(
  "accounts/createAccount",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `api/projects/${body?.projectId}/account-treso`,
        body?.values
      );
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: "accounts",
  initialState,
  reducers: {},
  extraReducers: {
    [getAccounts.pending]: (state) => {
      state.status = "loading";
    },
    [getAccounts.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.accounts = action.payload.data?.data;
    },
    [getAccounts.failed]: (state, action) => {
      state.myProjects = "failed";
      state.error = action.error.message;
    },
    [checkBeforeDelete.pending]: (state) => {
      state.checking = true;
    },
    [checkBeforeDelete.fulfilled]: (state, action) => {
      state.canDelete = action.payload.data?.data;
      state.checking = true;
    },
    [checkBeforeDelete.failed]: (state, action) => {
      state.checking = false;
      state.error = action.error.message;
    },
    [deleteAccount.pending]: (state) => {
      state.deleteStatus = "loading";
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.deleteStatus = "succeeded";
      state.accounts = state.accounts.filter(
        (account) => account.id !== action.meta.arg.accountId
      );
    },
    [deleteAccount.rejected]: (state) => {
      state.deleteStatus = "failed";
    },
    [updateAccount.pending]: (state) => {
      state.updateStatus = "loading";
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.updateStatus = "succeeded";

      const index = state.accounts.findIndex((account) => {
        return Number(account.id) === Number(action.meta?.arg?.accountId);
      });

      if (index !== -1) {
        state.accounts[index] = action.payload?.data;
      }
    },
    [updateAccount.rejected]: (state) => {
      state.updateStatus = "failed";
    },
    [createAccount.pending]: (state, action) => {
      state.createStatus = "loading";
    },
    [createAccount.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
      state.accounts.push(action.payload?.data?.data); // Add the created account to the list
    },
    [createAccount.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.createError = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;

import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../Button";
import {
  openListObjectiveDialog,
  updateObjective,
} from "../../../data/slices/objectives";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import { message } from "antd";

function OpenBudget({ open, data, id, handleClose }) {
  const dispatch = useDispatch();

  const handleOpenCloseBudget = async (objectiveId, isLocked) => {
    handleClose(id);
    await dispatch(
      updateObjective({ data: { isLocked: !!!isLocked }, objectiveId })
    ).then((result) => {
      if (updateObjective.rejected.match(result)) {
        displayValidationErrors(result.payload);
      } else if (updateObjective.fulfilled.match(result)) {
        handleClose(id);
        message.success(result?.payload || "succès");
      }
    });
  };

  return (
    <Overlay show={open} closable={true} onClick={() => handleClose(id)}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          hasCloseButton
          onClose={() => handleClose(id)}
          projectTitle={
            "Note: Il peut parfois être judicieux de conserver en l’état un budget déjà enregistré afin d’en conserver l’historique, et d’en créer une version dupliquée sur laquelle vous effectuerez vos modifications."
          }
          subTitle={`Vous souhaitez déverrouiller un budget pour le modifier.`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Plutôt aller sur la fenêtre pour dupliquer le budget"}
            className={"action-button-budget"}
            onClick={() => {
              handleClose(id);
              dispatch(openListObjectiveDialog({ status: "true" }));
            }}
          />
          <Button
            text={"Déverrouiller le budget"}
            className={"action-button-budget"}
            onClick={() => {
              handleOpenCloseBudget(
                data?.objectif?.id,
                data?.objectif?.isLocked === 1
              );
            }}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default OpenBudget;
